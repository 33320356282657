import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
Location

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.less']
})
export class SidePanelComponent implements OnInit {
  deviceHeight:number;
  allOrder:boolean;
  searchOrder:boolean;
  newOrder:boolean;
  designBookings:boolean;
  browserCat:boolean;
  newProduct:boolean;
  cbInventory:boolean;
  mailing:boolean;
  pickup:boolean;  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  bpSubscription: Subscription;
  isHandset = false;
  headerName: string;
  showSideNav = false;
  userType: any;
  
  constructor(private location:Location,private router:Router,
    private breakpointObserver: BreakpointObserver) { 
    this.deviceHeight = window.innerHeight;
    this.bpSubscription = this.bpObserver.subscribe(
      (handset: BreakpointState) => {
        this.isHandset = handset.matches;
        if (!this.isHandset) {
          //this.selectedOrder = "";
        }
      }
    );
  }

  ngOnInit(): void {
    console.log(localStorage.getItem('user'),'Name')
    if(JSON.parse(localStorage.getItem('user'))){
      this.userType = JSON.parse(localStorage.getItem('user')).user_type;
    console.log(this.userType,'Name')

    }
    if(this.location.path() === '/admin/dashboard/orders/active'){
      this.allOrder = true;
      this.headerName = 'All Orders';
    } else  if(this.location.path() === '/admin/dashboard/orders/search'){
      this.searchOrder = true;
      this.headerName = 'Search Orders';

    } else  if(this.location.path() === '/admin/dashboard/orders/pickups'){
      this.pickup = true;
      this.headerName = 'Order Pickups';

    } else  if(this.location.path() === '/admin/dashboard/orders/new-order'){
      this.newOrder = true;
      this.headerName = 'New Order';
    } else  if(this.location.path() === '/admin/dashboard/design/bookings'||
    this.location.path().includes('/design/projects/') ){
      this.headerName = this.location.path() === '/admin/dashboard/design/bookings' ? 'Design Bookings' : '';
      this.designBookings = true;
    } else  if(this.location.path() === '/design/designer/products/all'){
      this.browserCat = true;
      this.headerName = 'Product Catalogue';
    } else  if(this.location.path() === '/design/designer/add-product'){
      this.headerName = 'New Product';
      this.newProduct = true;
    } else  if(this.location.path() === '/admin/dashboard/cb-inventory'){
      this.cbInventory = true;
      this.headerName = 'CB / CB2 Inventory Lookup';
    }  
    else  if(this.location.path() === '/admin/dashboard/mailing-list'){
      this.mailing = true;
      this.headerName = 'Mailing List';
    } 
  }

  navigation(path:string){
    this.router.navigate([path]);
  }

  reset(){
    this.allOrder = false;
    this.searchOrder = false;
    this.newOrder = false;
    this.designBookings = false;
    this.browserCat =false;
    this.newProduct = false;
    this.cbInventory  = false;
  }

}
