import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import {
  ApiService,
  EventEmitterService,
  MatDialogUtilsService,
} from "src/app/shared/services";
import { EnvService } from "src/app/shared/services/env.service";

@Component({
  selector: "app-projects-landing",
  templateUrl: "./projects-landing.component.html",
  styleUrls: ["./projects-landing.component.less"],
})
export class ProjectsLandingComponent implements OnInit, OnDestroy {
  projectList: any[] = [];
  userName: string;
  designStatus: any[] = [];
  roomCodes: any[] = [];
  eventSubscription: Subscription;
  hasProjects = "";

  constructor(
    private api: ApiService,
    public envService: EnvService,
    private eventEmitterService: EventEmitterService,
    private matDialogUtil: MatDialogUtilsService
  ) {
    this.eventSubscription = this.eventEmitterService.userChangeEvent
      .asObservable()
      .subscribe((user) => {
        //   this.customerData.email = user.email;
        // if(user.email){
        this.initCalls();
        // }
      });
  }

  ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
  }
  initCalls() {
    if (this.isLoggedIn) {
      this.api.getDesignStausList().subscribe((res: any) => {
        console.log(res);
        this.designStatus = res;
        this.api.getRoomCodes().subscribe((res: any) => {
          this.roomCodes = res;
          this.get();
        });
      });
    } else {
      this.matDialogUtil.openSignupDialog(
        window.innerWidth >= 600 ? false : true
      );
    }
  }
  ngOnInit(): void {}

  get() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.userName = user.name; // user.first_name + ' ' + user.last_name;
    // this.userName = t
    this.api.getAllAppointments("", "", user.id).subscribe((res) => {
      if (res && res.length > 0) {
        this.projectList = res; 
          this.hasProjects = "YES";
        
        this.projectList.forEach((f) => {

          let path =this.designStatus.find(
            (fs) => fs.code === f.status
          )?.redirect_path;

          f["btnAction"] = this.designStatus.find(
            (fs) => fs.code === f.status
          ).label_action;
          f["label"] = this.roomCodes.find((fs) => fs.code === f.room)?.label;
          f["path"] = path? path.replace('%',f.project_id):'' ;
          f["image"] =
            this.envService.assetBaseUrl.substring(
              0,
              this.envService.assetBaseUrl.length - 1
            ) + this.roomCodes.find((fs) => fs.code === f.room)?.image;
        });
        console.log(this.projectList, "PL");
      } else {
        this.hasProjects = "NO";

      }
    },err=>{
      this.hasProjects = "NO";
    });
  }

  get isLoggedIn(): boolean {
    if (JSON.parse(localStorage.getItem("user"))) {
      return (
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).email &&
        JSON.parse(localStorage.getItem("user")).email.length > 0
      );
    } else {
      return false;
    }
  }
}
