import { Component, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from "@angular/cdk/layout";
import {
  ApiService,
  EventEmitterService,
  MatDialogUtilsService,
  UtilsService,
} from "../../../shared/services";
import { Router } from "@angular/router"; 
import { MatDialog } from "@angular/material/dialog";
import { InactiveItemsDialogComponent } from "./inactive-items-dialog/inactive-items-dialog.component";
import { EnvService } from "../../../shared/services/env.service";
import { ChatService } from "src/app/shared/services/api/chat.service";

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.less"],
})
export class CartComponent implements OnInit {
  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );

  bpSubscription: Subscription;
  tabletSubscription: Subscription;
  isHandset: boolean;
  quantityValue = 1;
  cartProducts = [];
  subTotal = 0;
  cartProductsLength: number;
  isProductFetching: boolean;
  spinner = "assets/image/spinner.gif";
  emptyCart = true;
  isCartLoading: boolean;
  isLoggedIn: boolean;
  eventSubscription: Subscription;
  numberOfProducts = 0;
  userType = 0;
  outOfStock: any[];
  productSelections: any;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private apiService: ApiService,
    private matDialogUtils: MatDialogUtilsService,
    private router: Router,
    private eventEmitterService: EventEmitterService,
    private utils: UtilsService,
    public matDialog: MatDialog,
    public env: EnvService,
    private chatService:ChatService
  ) {}

  ngOnInit(): void {
    this.isCartLoading = true;
    this.bpSubscription = this.bpObserver.subscribe(
      (handset: BreakpointState) => {
        this.isHandset = handset.matches;
      }
    );
    this.eventSubscription = this.eventEmitterService.userChangeEvent
      .asObservable()
      .subscribe((user) => {
        if (user.user_type === 1) {
          this.isLoggedIn = true;
        }
        this.getCartProducts();
        this.userType = JSON.parse(localStorage.getItem("user")).user_type;
      });
    this.userType = JSON.parse(localStorage.getItem("user")).user_type;
  }
  getProductSelections(sku, index) {
    const [first, ...rest] =  sku.split("-");
    let priceGrp =[];
    let finalPriceGrp =[];
    let sellingPrice = 0;
    this.apiService.getProductSelection(sku.split("-")[0]).subscribe(
      (payload: any) => {
        this.productSelections = payload;
        console.log(rest);
        for(let i=0;i<rest.length;i++){
            this.productSelections.selection[i].options.forEach(f=>{
                if(f.choice_id==rest[i]){
                  priceGrp.push(f.priceGroup); 
                }
            })
        }
        const [pfirst, ...prest] = priceGrp;
        if (priceGrp.length > 1) {
          prest.forEach((elem) => { 
            finalPriceGrp.push(`${pfirst},${elem}`);
          });
        } else {
          finalPriceGrp = priceGrp;
        }

    finalPriceGrp.forEach((f) => {
        let index = payload.Pricedetails
          .map((m) => m.priceGroup)
          .findIndex((n) => n === f);
        if (index > -1) {
          sellingPrice = sellingPrice + +payload.Pricedetails[index].sellingPrice;
        }
      });
        this.cartProducts[index].retail_price = ((+this.cartProducts[index].retail_price) + sellingPrice)* this.cartProducts[index].count;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getCartProducts() {
    this.isProductFetching = true;
    this.outOfStock = [];
    this.apiService.getCartProduct(false, null).subscribe(
      (payload: any) => {
        this.cartProducts = payload.products;
        this.cartProducts.forEach((m, i) => {
            if(m.is_virtual=='1'){
          this.getProductSelections(m.product_sku, i);
            }
        });
        // this.cartProducts.map(m => {
        //     if(m.site=='West Elm'){
        //         m.site = 'westelm';
        //     } else if(m.site=='World Market'){
        //         m.site = 'nw';
        //     } else if(m.site=='CB2'){
        //         m.site = 'cb2';
        //     } else if(m.site=='Crate&Barrel'){
        //         m.site = 'cab';
        //     }
        // });
        this.cartProducts.map((m) => {
          m.activeProduct = JSON.parse(
            JSON.stringify({ inventory_product_details: m })
          );

          if (!m.inventory.in_inventory && m.is_virtual == 0) {
            this.outOfStock.push(m);
          }
        });
        this.cartProductsLength = 0;
        this.emptyCart = this.cartProducts.length === 0;
        this.subTotal = payload.order.sub_total;
        this.isCartLoading = false;
        this.isProductFetching = false;
        this.numberOfProducts = this.cartProducts.reduce((acc, product) => {
          acc += product.count;
          return acc;
        }, 0);
      },
      (error: any) => {
        this.isCartLoading = false;
        this.isProductFetching = false;
        this.emptyCart = true;
        console.log(error);
      }
    );
  }

  clearOOSItem() {
    this.outOfStock.forEach((f) => {
      this.removeProduct(f, f.count);
    });
  }
  onDestroy(): void {
    this.bpSubscription.unsubscribe();
    this.eventSubscription.unsubscribe();
  }

  isDiscounted(product): boolean {
    return Number(product.was_price) > Number(product.retail_price);
  }

  increaseQuantity(product, quantity) {
    const postData = {
      product_sku: product.product_sku,
      count: quantity,
      parent_sku: product.parent_sku,
      isServiceRepOrder: 0,
      is_virtual: product.is_virtual=='1'?1:0,
    };
    this.apiService.addCartProduct(postData).subscribe(
      (payload: any) => {
        this.getCartProducts();
        this.utils.updateLocalCart(quantity);
        this.eventEmitterService.updateCartIcon();
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  decreaseQuantity(product, quantity) {
    // const postData = {
    //   product_sku: product.product_sku,
    //   count: quantity,
    //   parent_sku: product.parent_sku,
    //   isServiceRepOrder: 0,
    //   is_virtual: product.is_virtual=='1'?1:0,
    // };
    
    let data = {isServiceRepOrder:0,
      "products":[
         {"product_sku":product.product_sku,"count":quantity},
      ],
      is_virtual: product.is_virtual=='1'?1:0,
    };

    this.apiService.removeCartProduct(data).subscribe(
      (payload: any) => {
        this.getCartProducts();
        this.utils.updateLocalCart(-quantity);
        this.eventEmitterService.updateCartIcon();
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  removeProduct(product, quantity) {
    this.decreaseQuantity(product, quantity);
  }

  onQuantityChanged(product, count, quantity) {
    if (quantity >= count) {
      const updateQuantity = quantity - count;
      this.increaseQuantity(product, updateQuantity);
    } else {
      const updateQuantity = count - quantity;
      this.decreaseQuantity(product, updateQuantity);
    }
  }

  decodeHtml(text: string): string {
    const htmlComponent = document.createElement("textarea");
    htmlComponent.innerHTML = text;
    return htmlComponent.value;
  }

  openProductDetail(product) {
    if (product.parent_sku) {
      this.router.navigate([`product/${product.parent_sku}`]);
    } else {
        if(product.is_virtual=='1'){
            this.router.navigate([`product/${product.product_sku.split('-')[0]}`]);   
        }else {
      this.router.navigate([`product/${product.product_sku}`]);
        }
    }
  }

  openSignupDialog(device) {
    this.matDialogUtils.openSignupDialog(device);
  }

  seeMore(category) {
    this.router.navigateByUrl(`/products/all?${category}=true`);
  }

  counter(i: number) {
    i > 20 ? (i = 20) : i;
    return new Array(i);
  }
  secureCheckout() {
    if (this.outOfStock.length > 0) {
      this.clearOOSItem();

      this.matDialog
        .open(InactiveItemsDialogComponent, {
          disableClose: true,
          data: { oos: this.outOfStock, handSet: this.isHandset },
        })
        .afterClosed()
        .subscribe((res: any) => {
          if (res === "NoThanks") {
            if (this.cartProducts.length == this.outOfStock.length) {
              this.router.navigate(["/wishlist"]);
            }
            this.getCartProducts();
            this.matDialog.closeAll();
          }
        });
    } else {
      this.router.navigate(["/checkout"]);
    }
  }
  openLink(url) {
    this.router.navigate([]).then(() => {
      window.open(`${url}`);
    });
  }
  query='';
  isToggleChecked=true;
  assignQuery() {
    this.matDialog.closeAll();
    sessionStorage.removeItem('promptCategory'); 
    if (this.isToggleChecked) {
      this.chatService.query = this.query;
      this.router.navigate(["/chat"]);
    } else { 
      const data = `?query=${this.query}`
      this.router.navigateByUrl(`/search${data}`).then(() => {
        this.query = "";
      });
    }
  }
}
