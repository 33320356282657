import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ApiService } from "src/app/shared/services";

import * as JSZip from "jszip";

@Component({
  selector: "app-view-files",
  templateUrl: "./view-files.component.html",
  styleUrls: ["./view-files.component.less"],
})
export class ViewFilesComponent implements OnInit, OnChanges {
  @Input() isHandset: boolean;
  @Input() projectImages: any[]=[];
  imageResponse: any[] = [];
  InspfirstCol: any[] = [];
  InspsecondCol: any[] = [];
  InspthirdCol: any[] = [];

  RoomfirstCol: any[] = [];
  RoomsecondCol: any[] = [];
  RoomthirdCol: any[] = [];

  FloorfirstCol: any[] = [];
  FloorsecondCol: any[] = [];
  FloorthirdCol: any[] = [];

  KeepfirstCol: any[] = [];
  KeepsecondCol: any[] = [];
  KeepthirdCol: any[] = [];
  inpirationImages: any[]=[];
  roomImages: any[]=[];
  keepImages: any[]=[];
  floorImages: any[]=[];
  designData: any[]=[];
  currentTab = 'Inspiration';
  constructor(private api: ApiService) {}

  changeTab(t:string){
    this.currentTab =t;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes.projectImages && changes.projectImages.currentValue){
      this.projectImages = changes.projectImages.currentValue;
    }
  }

  ngOnInit(): void { 
    this.inpirationImages = this.projectImages[0]?.intakeimage.filter(f=>f.type === 'inspo')[0]?.images;
    this.roomImages = this.projectImages[0]?.intakeimage.filter(f=>f.type === 'current')[0]?.images;
    this.keepImages = this.projectImages[0]?.intakeimage.filter(f=>f.type === 'keep')[0]?.images;
    this.floorImages = this.projectImages[0]?.intakeimage.filter(f=>f.type === 'plans')[0]?.images;

    
    for (let i = 0; i < this.inpirationImages?.length; i++) {
      if (i % 3 == 0) {
        this.InspfirstCol.push(this.inpirationImages[i]);
      } else if (i % 3 === 1) {
        this.InspsecondCol.push(this.inpirationImages[i]);
      } else {
        this.InspthirdCol.push(this.inpirationImages[i]);
      }
    }

    for (let i = 0; i < this.roomImages?.length; i++) {
      if (i % 3 == 0) {
        this.RoomfirstCol.push(this.roomImages[i]);
      } else if (i % 3 === 1) {
        this.RoomsecondCol.push(this.roomImages[i]);
      } else {
        this.RoomthirdCol.push(this.roomImages[i]);
      }
    }

    for (let i = 0; i < this.keepImages?.length; i++) {
      if (i % 3 == 0) {
        this.KeepfirstCol.push(this.keepImages[i]);
      } else if (i % 3 === 1) {
        this.KeepsecondCol.push(this.keepImages[i]);
      } else {
        this.KeepthirdCol.push(this.keepImages[i]);
      }
    }

    for (let i = 0; i < this.floorImages?.length; i++) {
      if (i % 3 == 0) {
        this.FloorfirstCol.push(this.floorImages[i]);
      } else if (i % 3 === 1) {
        this.FloorsecondCol.push(this.floorImages[i]);
      } else {
        this.FloorthirdCol.push(this.floorImages[i]);
      }
    }
  }
 
  download() {
    let finalImages = [];

    this.inpirationImages.forEach(f=>{
      finalImages.push({image:f.image})
    });

    this.roomImages.forEach(f=>{
      finalImages.push({image:f.image})
    });

    this.keepImages.forEach(f=>{
      finalImages.push({image:f.image})
    });

    this.floorImages.forEach(f=>{
      finalImages.push({image:f.image})
    });

    finalImages.forEach((f) => {
      if (f.image) {
        const imageUrl =
          `/${this.api.projectId}/client_submission/` +
          f.image.split(`/${this.api.projectId}/client_submission/`)[1];
        this.getImages(
          f.image,
          f.image.split(`/${this.api.projectId}/client_submission/`)[1],
          finalImages
        );
      }
    });
  }
  getImages(data: any, name: string, fullData: any[]) {
    this.api.getImageBlob(data).then((res) => {
      this.imageResponse.push({ name: name, content: res });
      if (fullData.length === this.imageResponse.length) {
        this.downloadZip();
      }
    });
  }
  async downloadZip() {
    const files = this.imageResponse;

    const zip = new JSZip();

    for (const file of files) {
      zip.file(file.name, file.content);
    }

    const zipBlob = await zip.generateAsync({ type: "blob" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(zipBlob);
    link.download = `${this.api.projectId}_documents.zip`;
    link.click();
  }

  openFullScreen(event: MouseEvent): void {
    const imageElement = event.target as HTMLImageElement;

    // Create a div to cover the screen and display the image
    const fullScreenImage = document.createElement('img');
    fullScreenImage.src = imageElement.src;
    fullScreenImage.className = 'full-screen-image';

    // Append the image to the body
    document.body.appendChild(fullScreenImage);

    // Make the image full-screen
    if (fullScreenImage.requestFullscreen) {
      fullScreenImage.requestFullscreen();
    } else if (fullScreenImage['webkitRequestFullscreen']) { // Safari
      fullScreenImage['webkitRequestFullscreen']();
    } else if (fullScreenImage['msRequestFullscreen']) { // IE/Edge
      fullScreenImage['msRequestFullscreen']();
    }

    // Add a close functionality to exit fullscreen
    fullScreenImage.onclick = () => {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document['webkitExitFullscreen']) {
        document['webkitExitFullscreen']();
      } else if (document['msExitFullscreen']) {
        document['msExitFullscreen']();
      }
      fullScreenImage.remove();
    };
  }
}
