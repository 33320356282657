import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ApiService, UtilsService } from "src/app/shared/services";
import { EnvService } from "src/app/shared/services/env.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-stripe-payment",
  templateUrl: "./stripe-payment.component.html",
  styleUrls: ["./stripe-payment.component.less"],
})
export class StripePaymentComponent implements OnInit,OnDestroy {
  isHandset = false;
  currentPage = 1;
  desPayResp: any;
  clientSecret: any;
  stripe: any;
  elements: any;
  deviceHeight: number;
  deviceWidth: number;
  errorMsg: string;
  amount: number;
  @Input() totalAmount: number;
  @Input() projectId: string; 
  @Output() executePayment = new EventEmitter();
  @Output() executeFailedPayment = new EventEmitter();
  paymentInProgress: boolean;
  paymentError: string;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private utils: UtilsService,
    private router: Router,
    public env: EnvService,
    private cookie: CookieService
  ) {}
ngOnDestroy(): void {
  var stripeScript = document.getElementById("stripe-script");
if (stripeScript) {
    stripeScript.remove();
}
}
  ngOnInit(): void {
    // this.invokeStripe();

    this.utils.isHandset().subscribe((handset) => {
      this.isHandset = handset.matches;
    });

    this.deviceHeight = window.innerHeight;
    this.deviceWidth = window.innerWidth;
    this.initStripeDesignPayment();
    
  }
  ngAfterViewInit(): void {}
  initStripeDesignPayment() {
    this.api.stripeDesignPayment().subscribe((res: any) => { 
      this.desPayResp = res;
      this.amount = res.amount / 100;
      this.clientSecret = res.client_secret;
      const options = {
        clientSecret: this.clientSecret,
        appearance: {
          theme: "stripe",
        },
      };
  this.invokeStripe(options)
    });
  }

  invokeStripe(options) {
    if (!window.document.getElementById("stripe-script")) {
      const script = window.document.createElement("script");
      script.id = "stripe-script";
      script.type = "text/javascript";
      script.src = "https://js.stripe.com/v3/";
      script.onload = () => {
        this.stripe = (<any>window).Stripe(environment.STRIPE_API_KEY);
        this.elements = this.stripe.elements(options);

        const paymentElement = this.elements.create("payment");
        paymentElement.mount("#payment-element");
      };
      window.document.body.appendChild(script);
     
    }
  }

  async pay() {
    this.paymentInProgress = true;
    this.confirmPayment();
  }
  async confirmPayment() {
    let user = JSON.parse(localStorage.getItem("user"));
    let elements = this.elements;
    const { error, paymentIntent } = await this.stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://seededhome.com/design/book",
        payment_method_data: {
          billing_details: {
            name: user.firstName + " " + user.lastName,
            email: user.email,
            phone: "",
          },
        },
      },
      redirect: "if_required",
    });

    let data = {
      project_id:this.projectId,
      payment_id: paymentIntent.id,
      status: paymentIntent.status,
      payment_method: paymentIntent.payment_method,
    };

    this.api.stripeCustomerCreate(data).subscribe(
      (res: any) => {
        if (res) {
          this.paymentInProgress = false; 
          if(res.status){
          this.executePayment.emit(res);
          } else {
            this.paymentInProgress = false;
            this.paymentError =
              " Please try again or use a different payment method.";
          }
        }
      },
      (err) => {
        this.paymentInProgress = false;
        this.paymentError =
          " Please try again or use a different payment method.";
      }
    );
  }
}
