import { AfterViewInit, Component, HostListener, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from "@angular/cdk/layout";

import {
  Event as RouterEvent,
  NavigationStart,
  Router,
  Scroll,
} from "@angular/router";
import { boardRoutesNames } from "./modules/board/board.routes.names";
import { ViewportScroller } from "@angular/common";
import { filter, pairwise } from "rxjs/operators";
import { EnvService } from "./shared/services/env.service";
import { Location } from "@angular/common";
import { ChatService } from "./shared/services/api/chat.service";
import { ApiService } from "./shared/services";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = "";

  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  tabletObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Tablet
  );

  bpSubscription: Subscription;
  tabletSubscription: Subscription;
  isHandset: boolean;
  isTablet = false;
  isMinimalMode = false;
  isGpt: boolean;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public router: Router,
    private chatService:ChatService,
    private api:ApiService,

    private viewportScroller: ViewportScroller,
    public env: EnvService,
    private location: Location
  ) {
    if(this.location.path() === '/' || this.location.path() === 'chat' || this.location.path() === '/c' ){
      this.getPromptsSearch();
  
     }
     if(this.location.path().includes('/c/') || this.location.path().includes('/chat')){
      if(this.chatService.brandList.length==0){
      this.getBrands(); 
      }
     }
    this.isGpt =
      this.location.path().match(/gpt/) &&
      this.location.path().split("-").length > 1;
    this.title = env.appName;
    this.router.events
      .pipe(
        filter((e: RouterEvent): e is Scroll => {
          return e instanceof Scroll;
        }),
        pairwise()
      )
      .subscribe((eventPair) => {
        const previousEvent = eventPair[0];
        const event = eventPair[1];
        if (event.position) {
          // backward navigation
          this.viewportScroller.scrollToPosition(event.position);
        } else if (event.anchor) {
          // anchor navigation
          this.viewportScroller.scrollToAnchor(event.anchor);
        } else {
          // forward navigation
          if (
            previousEvent.routerEvent.urlAfterRedirects.split("?")[0] !==
            event.routerEvent.urlAfterRedirects.split("?")[0]
          ) {
            // Routes don't match, this is actual forward navigation
            // Default behavior: scroll to top
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }
      });
    router.events.subscribe((navigation) => {
      if (
        navigation instanceof NavigationStart &&
        navigation.url.match(`/${boardRoutesNames.BOARD_EMBED}/`)
      ) {
        this.isMinimalMode = true;
      }
    });
  }


  getBrands() {
    this.api.getAllBrandNames().subscribe((res) => {
      this.chatService.brandList = res;
    });
  }

  ngAfterViewInit(): void {}
  get isDesign() {
    return this.router.url === "/design";
  }
  get isMerchandising() {
    return this.router.url.includes("/design/designer/products/all");
  }
  @HostListener("window:scroll")
  onWindowScroll() {
    // In chrome and some browser scroll is given to body tag
    const pos =
      (document.documentElement.scrollTop || document.body.scrollTop) +
      document.documentElement.offsetHeight;
    const max = document.documentElement.scrollHeight - 100;
    // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
    const intercom = document.getElementsByClassName(
      "intercom-lightweight-app"
    )[0];
    if (pos >= max && intercom) {
      intercom.classList.add("hidden");
    } else if (intercom) {
      intercom.classList.remove("hidden");
    }
  }

  get scroll(): boolean {
    if (document.body.scrollHeight  > document.body.clientHeight) {
      return true;
    } else {
      return false;
    }
  }
  ngOnInit(): void {
    
   

    this.bpSubscription = this.bpObserver.subscribe(
      (handset: BreakpointState) => {
        this.isHandset = handset.matches;
      }
    );
    this.tabletSubscription = this.tabletObserver.subscribe(
      (tablet: BreakpointState) => {
        this.isTablet = tablet.matches;
      }
    );
  }

  getPromptsSearch(){
    this.chatService.getPromptSearch().subscribe((resp:any[])=>{
      this.chatService.promptSearchList = resp.find(f=>f.type==='primary').prompt_details;
      this.chatService.categoriesList = resp.find(f=>f.type==='secondary').prompt_details;
      this.chatService.discoveryList = resp.find(f=>f.type==='discover').prompt_details;// this.chatService.discoveryList;

    })
  }

  onDestroy(): void {
    this.bpSubscription.unsubscribe();
  }
}
