import { DatePipe } from "@angular/common";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { ApiService, EventEmitterService } from "src/app/shared/services";
import * as JSZip from "jszip";
import { Observable, Subscription } from "rxjs";
import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
@Component({
  selector: "app-design-dashboard",
  templateUrl: "./design-dashboard.component.html",
  styleUrls: ["./design-dashboard.component.less"],
  providers: [DatePipe],
})
export class DesignDashboardComponent implements OnInit, AfterViewInit {
  scheduleList: any[] = [];
  imageResponse: any[] = [];
  currentProjectId: any;
  statusList: any[] = [];
  timePeriodList: any[] = [];
  designerList: any[] = [];
  timePeriod: any = "";
  status: any = ['01','02','03']; // ['New','Active'];
  designer: any = "";
  total: number = 0; 
  curPage: number = 1;
  innerWidth:number;
  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  bpSubscription: Subscription;
  isHandset = false;

  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private snackbar: MatSnackBar,
    private router: Router,
    private eventEmitterService: EventEmitterService,
    private snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver
  ) {
    this.bpSubscription = this.bpObserver.subscribe(
      (handset: BreakpointState) => {
        this.isHandset = handset.matches; 
      }
    );
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.getDesignStatus();
    this.getDesignerName();
    this.getDesignBookings();
    if (
      (
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).email &&
        JSON.parse(localStorage.getItem("user")).email
      ).length > 0
    ) {
    } else {
      this.router.navigate(["/"]);
    } 
    this.eventEmitterService.userChangeEvent.asObservable().subscribe((res) => {
      if (
        (
          JSON.parse(localStorage.getItem("user")) &&
          JSON.parse(localStorage.getItem("user")).email &&
          JSON.parse(localStorage.getItem("user")).email
        ).length > 0
      ) {
      } else {
        this.router.navigate(["/"]);
      }
    });
  }

  getDesignerName() {
    this.api.getDesignProfile().subscribe((res) => {
      this.designerList = res;
    });
  }

  pageChanged(event) {
    this.curPage = event;
  }

  getDesignBookings() {
    this.api
      .getAllDesignBookings(this.status, this.designer, this.timePeriod)
      .subscribe((res: any) => {
        this.scheduleList = res;
        this.scheduleList.forEach((f) => {
          f.isEdit = false;
          f.schedule = this.getPstTime(f.schedule_date);
        });
      });
  }
  getPstTime(date: any) {
    let dateObj: Date = typeof date == "string" ? new Date(date) : date;
    if (dateObj) {
      let offset = 7 * 60 * 60 * 1000;
      dateObj.setTime(dateObj.getTime() - offset);
      return dateObj;
    }
  }

  getDesignStatus() {
    this.api.getDesignStausList().subscribe((resp: any) => {
      this.statusList = resp;
    });
  }

  saveDesignStatus(s, e) {
    let obj = {
      project_id: s.project_id,
      status: e,
    };
    this.api.updateDesignStatus(obj).subscribe((resp) => {
      this.snackbar.open("Status updated successfully", "x", {
        duration: 2000,
      });
    });
  }

  schedule() {
    let payload = {
      booking_id: "",
    };
    this.api.sendDesignBookingMail(payload).subscribe((res) => {
      this.snackbar.open("Mail scheduled successfully", "x");
      this.getDesignBookings();
    });
  }

  ngAfterViewInit(): void {
    this.hideIntercomIcon();
  }

  hideIntercomIcon() {
    const interval = setInterval(() => {
      const intercom = document.getElementsByClassName(
        "intercom-lightweight-app"
      )[0];
      if (intercom) {
        intercom.classList.add("dn");
        clearInterval(interval);
      }
    }, 500);

    const intervalx = setInterval(() => {
      const intercomx = document.getElementsByClassName("intercom-app")[0];
      if (intercomx) {
        intercomx.classList.add("dn");
        clearInterval(intervalx);
      }
    }, 500);
  }

  goToPage(page) {
    if(page===-1){
      this.curPage=  Math.ceil(this.scheduleList.length / 10);
    } else {
       this.curPage = page;
    }
   
  }

  get isLastPage() {
    return this.curPage === Math.floor(this.scheduleList.length / 10);
  }

  // getColor(status: string) {
  //   if (status.toUpperCase() == "SUCCESS") {
  //     return {
  //       "background-color": "#4caf50",
  //       color: "white",
  //       height: "22px",
  //       "border-radius": "8px",
  //     };
  //   } else if (status.toUpperCase() == "OPEN") {
  //     return {
  //       "background-color": "#add8e6",
  //       color: "black",
  //       height: "22px",
  //       "border-radius": "8px",
  //     };
  //   } else if (status.toUpperCase() == "PAID") {
  //     return {
  //       "background-color": "#f0ad4e",
  //       color: "white",
  //       height: "22px",
  //       "border-radius": "8px",
  //     };
  //   }
  // }

  getColor(order) {
    const orderCode = this.statusList.filter(
      (val) => val.code === order.status
    )[0];
    if (orderCode) {
      return {
        "background-color": orderCode.bg_hex,
        color: orderCode.font_hex, //'white',
        padding: "6px 5px 3px 5px",
        "border-radius": "8px",
      };
    }
  }
  getServerDate(date: any): any {
    const y = new Date(date).toISOString();
    return y;
  }
  getDesignImages(data: any) {
    this.currentProjectId = data.project_id;
    this.api.getDesignImages(this.currentProjectId).subscribe((res: any) => {
      if (res && res.length > 0) {
        res.forEach((f) => {
          if (f.image) {
            const imageUrl =
              `/${this.currentProjectId}/client_submission/` +
              f.image.split(`/${this.currentProjectId}/client_submission/`)[1];
            this.getImages(
              f.image,
              f.image.split(`/${this.currentProjectId}/client_submission/`)[1],
              res
            );
          }
        });
      }
    });
  }
  getImages(data: any, name: string, fullData: any[]) {
    this.api.getImageBlob(data).then((res) => {
      this.imageResponse.push({ name: name, content: res });
      if (fullData.length === this.imageResponse.length) {
        this.downloadZip();
      }
    });
  }
  async downloadZip() {
    const files = this.imageResponse;

    const zip = new JSZip();

    for (const file of files) {
      zip.file(file.name, file.content);
    }

    const zipBlob = await zip.generateAsync({ type: "blob" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(zipBlob);
    link.download = `${this.currentProjectId}_documents.zip`;
    link.click();
  }
  goToIntake(s) {
    this.router.navigate([
      `design/projects/${s.project_id}/view-project-intake`,
    ]);
  }
}
