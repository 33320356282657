import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddToOrderComponent } from 'src/app/modules/order-list/add-to-order/add-to-order.component';
import { ApiService } from 'src/app/shared/services';
import { AddShoppingProductDialogComponent } from '../add-shopping-product-dialog/add-shopping-product-dialog.component';

@Component({
  selector: 'app-shopping-list',
  templateUrl: './shopping-list.component.html',
  styleUrls: ['./shopping-list.component.less']
})
export class ShoppingListComponent implements OnInit {
  @Input() isHandset: boolean;
  productListings: any[] = [];
  isFetched: boolean;
  qtyList: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  total=0;
  spinner = "assets/image/spinner.gif";

  constructor(private api: ApiService,private snackbar:MatSnackBar,
    private dialog:MatDialog
  ) { }

  ngOnInit(): void {
    console.log(this.api.projectId)
    this.getShoppingList();
  }

  getShoppingList() {
    this.isFetched = false;
    // this.api.projectId = '170874'
    this.api.getShoppingList(this.api.projectId).subscribe((res: any) => {

      if (res && res.products) {
        this.productListings = res.products;
        this.productListings.forEach(f => {
          f['checked'] = false;
          f['qty'] = JSON.parse(JSON.stringify(f.count)) ;

        });
        this.total = this.productListings.reduce((a,b)=>{
          return a+b.total_price;
        },0)
        this.isFetched = true;
      }
    })
  }

  remove(){
    let productsToRemove =[];

    this.productListings.forEach(f=>{
      if(f.checked){
     productsToRemove.push( {product_sku:f.product_sku,count:f.count})
      }
    })
    this.decreaseQuantity(productsToRemove,true)
  }

  create(){

  }

  get selections():boolean{
    return this.productListings.some(f=>f.checked);
  }


  onQuantityChanged(product, count, quantity) {
    if ((+quantity) >= count) {
      const updateQuantity = (+quantity) - count;
      this.increaseQuantity(product, updateQuantity);
    } else {
      let productsToRemove = [{product_sku:product.product_sku, count: count}]
      const updateQuantity = count - (+quantity);
      this.decreaseQuantity(productsToRemove,false);
    }
  }

  openAddToOrder() {
    this.dialog
      .open(AddShoppingProductDialogComponent, {
        // width: "700px",
        data: this.api.projectId,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getShoppingList();
        }
      });
  }


  increaseQuantity(product, quantity) {
    const postData = {
      product_sku: product.product_sku,
      count: +quantity,
      parent_sku: product.parent_sku,
      isServiceRepOrder: 1,
      cart_id: this.api.projectId,


    };
    this.api.addCartProduct(postData).subscribe(
      (payload: any) => { 
        this.snackbar.open('Quantity updated','x',{duration:3000})
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  decreaseQuantity(productsToRemove, isCompleteRemove) {
    let data = {isServiceRepOrder:1,
      "products":productsToRemove, 
    cart_id: this.api.projectId, 

    };
    this.api.removeCartProduct(data).subscribe(
      (payload: any) => { 
        if(isCompleteRemove){
        this.snackbar.open('Produce removed successfully','x',{duration:3000});
        this.getShoppingList();

        } else {
        this.snackbar.open('Quantity updated','x',{duration:3000});

        }

      },
      (error: any) => {
        console.log(error);
      }
    );
  }
 


}
