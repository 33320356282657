import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService, EventEmitterService, UtilsService } from 'src/app/shared/services';

@Component({
  selector: 'app-chat-prd-preview',
  templateUrl: './chat-prd-preview.component.html',
  styleUrls: ['./chat-prd-preview.component.less']
})
export class ChatPrdPreviewComponent implements OnInit, OnChanges {
@Input() productDetails:any;
@Input() isHandset:boolean;
@Output() navigate = new EventEmitter();
@Output() close = new EventEmitter();
@Output() emitPin = new EventEmitter();

public Math: Math = Math;
slideIndex = 0;

productImages:string[]=[];
  productInfo: any;
  isSingleDimension: boolean;
  dimensionExist: boolean;
  displayable: any;
  product_url: any;
  show_redirect: string;
  sku: any;
  isPinned: boolean;
  constructor(private api:ApiService,public utils:UtilsService,private router:Router,
    private utilsService: UtilsService,
private eventEmitterService:EventEmitterService,
    private snackBar:MatSnackBar,
  ) { }

  get deviceHeight(){
    return  this.isHandset? window.innerHeight-100 :  window.innerHeight-40;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.productDetails.currentValue){ 
      this.productInfo = null;
      this.displayable = this.productDetails.displayable;
      this.product_url = this.productDetails.product_url;
      this.sku = this.productDetails.sku;
      this.getProduct();

    }
  }

  getProduct(){
    this.api.getProduct(this.productDetails.sku).subscribe(res=>{
      this.productImages = []; 
      this.productInfo = res.product;
      this.show_redirect = this.productInfo.show_redirect;
      this.dimensionExist = this.productInfo.dimension.length > 0;

      this.productImages.push(res.product.main_image);
      this.productInfo.product_images_gallery.forEach(f=>{
      this.productImages.push(f);

      })
      this.isSingleDimension = this.productInfo.dimension.length === 1;
      setTimeout(() => {
        this.currentSlide(1);
      }, 0);
    })
  }
  navigateToPrd(){
    this.router.navigate([]).then(() => {
      window.open(this.product_url);
    });
    // const user = JSON.parse(localStorage.getItem("user"));

    // if (this.displayable === "false") {
      
    //   this.router.navigate([]).then(() => {
    //   window.open(this.product_url);
    // });
    // } else if (
    //   this.displayable === "authorized"&&
    //   (("" + user.user_type) == "01" ||
    //     ("" + user.user_type) == "0") &&
    //   this.show_redirect === 'TRUE'
    // ) {
      
    //   this.router.navigate([]).then(() => {
    //   window.open(this.product_url);
    // });
    // } else if (
    //   this.displayable === "seeded" &&
    //   (("" + user.user_type) == "0" ||
    //   ("" + user.user_type) == "01" ||
    //     ("" + user.user_type) == "02") &&
    //   this.show_redirect === 'TRUE'
    // ) {
    //   this.router.navigate([]).then(() => {
    //     window.open(this.product_url);
    //   });
    // } else if (
    //   (this.displayable === "true" ||
    //     this.displayable === "restricted" ||
    //     this.displayable === "preview")  
    // ) {
    //   this.router.navigate([]).then(() => {
    //     window.open(`/product/${this.sku}`);
    //   });
    // }  else {
    //   this.router.navigate([]).then(() => {
    //     window.open(`/product/${this.sku}`);
    //   });
    // }
  }

  get isUserLoggedIn(): boolean {
    if (JSON.parse(localStorage.getItem("user"))) {
      return (
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).email &&
        JSON.parse(localStorage.getItem("user")).email.length > 0
      );
    } else {
      return false;
    }
  }

  wishlistProduct() {
    // event.stopPropagation();
    const user = JSON.parse(localStorage.getItem("user"));
    const neverShow = localStorage.getItem("login-prompt-never-show");
    if (!this.isUserLoggedIn) {
      // trigger signup window
      this.utilsService.openSignupDialog(false, true);
      return;
    }
    this.api
      .wishlistProduct(this.productInfo.sku, true, false)
      .subscribe((payload: any) => {
        this.productInfo.wishlisted = true;
        this.snackBar.open('Product added to waitlist','x',{duration:3000});
        // this.product.wishlisted = mark;
        // if (mark) {
        //   if (user.user_type === 0 && !neverShow) {
        //     this.matDialogUtils.openWishlistLoginPromptModal(this.product);
        //   } else {
        //     this.snackBarService.addToWishlist(sku);
        //   }
        // } else {
        //   this.snackBarService.removeIfExistsProduct(sku);
        // }
      });
  }
  buy(){
    this.router.navigate([]).then(() => {
      window.open(`/product/${this.sku}`);
    });
  }
  
  // get getBtnName():string{
  //   let name = 'View Details';
  //   const user = JSON.parse(localStorage.getItem("user"));

  //   if (this.displayable === "false") {
  //     name = `View On ${this.productInfo.brand_name}`
  //   } else if (
  //     this.displayable === "authorized"&&
  //     (("" + user.user_type) == "01" ||
  //       ("" + user.user_type) == "0") &&
  //     this.show_redirect === 'TRUE'
  //   ) {
  //     name = `View On ${this.productInfo.brand_name}`;
  //   } else if (
  //     this.displayable === "seeded" &&
  //     (("" + user.user_type) == "0" ||
  //     ("" + user.user_type) == "01" ||
  //       ("" + user.user_type) == "02") &&
  //     this.show_redirect === 'TRUE'
  //   ) {
  //     name = `View On ${this.productInfo.brand_name}`;
  //   } else if (
  //     (this.displayable === "true" ||
  //       this.displayable === "restricted" ||
  //       this.displayable === "preview")  
  //   ) {
  //   name = 'View Details'
  //   } 
  //   return name;
  // } 
  
  ngOnInit(): void {
    this.eventEmitterService.invokeFetchUser.subscribe((payload) => {
      this.productInfo.wishlisted = true;
      this.api
      .wishlistProduct(this.productInfo.sku, true, false)
      .subscribe((payload: any) => {
        this.productInfo.wishlisted = true;
        this.snackBar.open('Product added to waitlist','x',{duration:3000});
        // this.product.wishlisted = mark;
        // if (mark) {
        //   if (user.user_type === 0 && !neverShow) {
        //     this.matDialogUtils.openWishlistLoginPromptModal(this.product);
        //   } else {
        //     this.snackBarService.addToWishlist(sku);
        //   }
        // } else {
        //   this.snackBarService.removeIfExistsProduct(sku);
        // }
      });
      // this.getProduct();

    });
  }
  closeSidePanel(){
this.close.emit(true)
  }

  pin(){
    this.isPinned = !this.isPinned;
this.emitPin.emit(this.isPinned)

  }

  get previewWidth(){
 return   document.getElementById('preview').clientWidth;
  }

  plusSlides(n: number) {
    this.showSlides((this.slideIndex += n));
  }
  currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }
  showSlides(n) {
    let i;
    let slides = document.getElementsByClassName(
      "pSlides"
    ) as HTMLCollectionOf<HTMLElement>;
    if (slides.length > 0) {
      let dots = document.getElementsByClassName("dot");
      if (n > slides.length) {
        this.slideIndex = 1;
      }
      if (n < 1) {
        this.slideIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active-dot", "");
      }
      slides[this.slideIndex - 1].style.display = "block";
      if(dots && dots[this.slideIndex - 1]){
      dots[this.slideIndex - 1].className += " active-dot";
      }
    }
  }
}
